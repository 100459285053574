.banner {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: grey;
}

.hero img {
    border-radius: 50%;
    height: 400px;
    width: 400px;
}